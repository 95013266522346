import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="result-form"
export default class extends Controller {
  connect() {
    let paceInput = this.element;
    $('#user_result_units').on('change', (e) => {
      $('#user_result_score').trigger('change');
    });
    $('#user_result_score').on('change keyup', (e) => {
      let units = $('#user_result_units').val();
      if (units == 'kilometers') {
        paceInput.value = (e.target.value * 1000 / paceInput.dataset.work).toFixed(2);
      } else if (units == 'miles') {
        paceInput.value = (e.target.value * 1609 / paceInput.dataset.work).toFixed(2);
      } else {
        paceInput.value = (e.target.value / paceInput.dataset.work).toFixed(2);
      }
    })

  }
}
